import React from "react";
import Layout, { PageWrapper } from "../components/layout";
import { PageSection } from "../styles/index/_components";
import "twin.macro";
import Decorators from "../components/index/decorators";
import Online from "../images/vectors/undraw_Online_world_re_h4cb.svg";
import Pride from "../images/vectors/undraw_pride_y0te.svg";
import Powerful from "../images/vectors/undraw_Powerful_re_frhr.svg";
import Forming from "../images/vectors/undraw_Forming_ideas_re_2afc.svg";
import Link from "gatsby-link";

const Card = ({ title, desc, Image, link }) => {
  return (
    <div tw="flex flex-col rounded-2xl overflow-hidden justify-between">
      <div tw="flex flex-col justify-start">
        <div tw="py-6 font-bold text-2xl">{title}</div>
        <Image tw="w-full h-36" />
      </div>
      <div tw="flex flex-col justify-end">
        <div tw="px-8 font-thin py-6">{desc}</div>
        <div tw="flex items-center justify-center text-two pb-3">
          <Link to={link}>Przejdź</Link>
        </div>
      </div>
    </div>
  );
};

const Oferta = () => {
  return (
    <Layout>
      <PageSection>
        <PageWrapper tw="text-xl text-center items-center">
          <Decorators text={"Nasza oferta"} />
          <div tw="grid lg:grid-cols-3 grid-cols-1 gap-1 max-w-screen-lg">
            <Card
              title="Pomoc psychologiczna"
              desc="Pomoc w problemach związanych ze zdrowiem i dobrostanem psychicznym."
              Image={Powerful}
              link="pomoc-psychologiczna"
            />
            <Card
              title="Pomoc seksuologiczna"
              desc="Pomoc w problemach związanych ze zdrowiem i funkcjonowaniem seksualnym."
              Image={Pride}
              link="pomoc-seksuologiczna"
            />
            <Card
              title="Szybka porada online"
              desc="Szybka porada psychologiczna lub seksuologiczna online!"
              Image={Online}
              link="szybka-porada-on-line"
            />
          </div>
        </PageWrapper>
      </PageSection>
    </Layout>
  );
};

export default Oferta;
